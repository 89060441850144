import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "https://user-pv-arc"; // Optional: Über Umgebungsvariable konfigurieren

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={window.location.origin}
    authorizationParams={{
      audience: audience,
      // Du kannst hier auch zusätzliche Parameter hinzufügen, falls erforderlich
    }}
  >
    <App />
  </Auth0Provider>
);

reportWebVitals();
