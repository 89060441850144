import React, { useEffect, useState, useMemo, useCallback } from 'react';
import './styles/Spinner.css';
import './styles/Slider.css';

import logo from './logo/ARC.png';
import { DataSet } from 'vis-data';
import GraphNetwork from './NetworkGraph'; // Import the GraphNetwork component
import ChatInterface from './Interface'; // Import the Chat component

import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0 for Auth0 authentication functions

function App() {
  const [nodes, setNodes] = useState(new DataSet([]));
  const [edges, setEdges] = useState(new DataSet([]));
  const [distances, setDistances] = useState({});
  const [selectedDepth, setSelectedDepth] = useState(3); // Initialize with depth 3
  const [maxDepth, setMaxDepth] = useState(5); // Initialize with a default maximum depth
  const [highlightedNodeIds, setHighlightedNodeIds] = useState([]);
  const memoizedHighlightedNodeIds = useMemo(() => highlightedNodeIds, [highlightedNodeIds]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT;
  const LOGIN_URL = process.env.REACT_APP_ENVIRONMENT_URL;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "https://user-pv-arc"; // Optional: Über Umgebungsvariable konfigurieren

  const updateHighlightedNodeIds = (nodeIds) => {
    setHighlightedNodeIds(nodeIds);
  };

  const onSetSelectedDepth = (depth) => {
    setSelectedDepth(depth);
  };

  const { loginWithRedirect, logout, user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const isProduction = ['www.arcintelligence-factory.de', 
                        'demo-instance-eight.vercel.app', 
                        'www.demo-instance-eight.vercel.app',
                       'demo.arc-intelligence.de',
                        'www.demo.arc-intelligence.de'].includes(window.location.hostname);

  // Memoize getAuthHeaders to prevent re-creation on every render
  const getAuthHeaders = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: audience,
        },
      });
      return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      };
    } catch (error) {
      console.error('Error fetching access token:', error);
      return { 'Content-Type': 'application/json' }; // Fallback headers without Authorization
    }
  }, [getAccessTokenSilently, audience]);

  useEffect(() => {
    console.log('location:', window.location.hostname);
    console.log('isLoading:', isLoading);
    console.log('isProduction:', isProduction);
    console.log('isAuthenticated:', isAuthenticated);
    
    if (isProduction && !isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, isProduction]);

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    const checkUserGroup = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: audience,
          },
        });
        const response = await fetch(`${BASE_URL}/check-user-group`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: user.sub,
            group: `${ENVIRONMENT_NAME}`,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (!data.in_group) {
          handleLogout();
        }
      } catch (error) {
        console.error('Error checking user group:', error);
        // Optionally, you can redirect the user or show a message
        loginWithRedirect();
      }
    };

    if (isAuthenticated && !isLoading) {
      checkUserGroup();
    }
  }, [isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect, user, BASE_URL, ENVIRONMENT_NAME, audience]);

  useEffect(() => {
    // Function to parse the data if it's an escaped JSON string
    const parseData = (data) => {
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
          if (typeof data === 'string') {
            data = JSON.parse(data);
          }
        } catch (e) {
          console.error('Failed to parse data:', e);
        }
      }
      return data;
    };

    // Function to sanitize data by removing invalid entries
    const sanitizeData = (data) => {
      const isValidNode = (node) => node.id && node.label;
      const isValidEdge = (edge) => edge.from && edge.to;

      const sanitizedNodes = data.nodes ? data.nodes.filter(isValidNode) : [];
      const sanitizedEdges = data.edges ? data.edges.filter(isValidEdge) : [];

      return { nodes: sanitizedNodes, edges: sanitizedEdges };
    };

    // Fetch the graph data (nodes and edges)
    const fetchGraphData = async () => {
      try {
        const authHeaders = await getAuthHeaders();
        const response = await fetch(`${BASE_URL}/get-graph-data`, {
          method: 'GET',
          headers: authHeaders,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Graph data before parsing:', data);

        // Parse the data if necessary
        const parsedData = parseData(data);
        console.log('Parsed graph data:', parsedData);

        if (parsedData.nodes && parsedData.edges) {
          // Sanitize the data
          const sanitizedData = sanitizeData(parsedData);

          setNodes(new DataSet(sanitizedData.nodes));
          console.log('Nodes data:', sanitizedData.nodes);
          setEdges(new DataSet(sanitizedData.edges));
          console.log('Edges data:', sanitizedData.edges);
        } else {
          console.error('Invalid graph data format:', parsedData);
        }
      } catch (error) {
        console.error('Error fetching graph data:', error);
      }
    };

    // Fetch the distances data
    const fetchDistancesData = async () => {
      try {
        const authHeaders = await getAuthHeaders();
        const response = await fetch(`${BASE_URL}/get-graph-distance`, {
          method: 'GET',
          headers: authHeaders,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setDistances(data);
        console.log('Distances data:', data);
        const maxDistance = Math.max(...Object.values(data));
        console.log('Max distance:', maxDistance);
        setMaxDepth(maxDistance);
        if (maxDistance < 3) {
          setSelectedDepth(maxDistance);
        }
      } catch (error) {
        console.error('Error fetching distances data:', error);
      }
    };

    // Only fetch data if the user is authenticated
    if (isAuthenticated && !isLoading) {
      fetchGraphData();
      fetchDistancesData();
    }
  }, [isAuthenticated, isLoading, getAuthHeaders, BASE_URL, audience]);

  const filteredGraphData = useMemo(() => {
    const filteredNodes = nodes.get({
      filter: (node) => distances[node.id] <= selectedDepth,
    });

    let filteredEdges = edges.get({
      filter: (edge) =>
        filteredNodes.some((node) => node.id === edge.from) &&
        filteredNodes.some((node) => node.id === edge.to),
    });

    const edgeSet = new Set();
    filteredEdges = filteredEdges.filter(edge => {
      const edgeKey = `${edge.from}-${edge.to}`;
      if (edgeSet.has(edgeKey)) {
        return false;
      } else {
        edgeSet.add(edgeKey);
        return true;
      }
    });

    return { nodes: new DataSet(filteredNodes), edges: new DataSet(filteredEdges) };
  }, [nodes, edges, distances, selectedDepth]);

  const GraphNetworkMemo = React.memo(GraphNetwork);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" onClick={handleLogout} />
        <div className="filter-bar">
          <input
            type="range"
            min={1}
            max={maxDepth}
            value={selectedDepth}
            onChange={(e) => setSelectedDepth(parseInt(e.target.value))}
          />
          <span>{selectedDepth}</span>
        </div>
      </header>
      <div className="main-content"> {/* Flex container for main content */}
        <div className="graph-container">
          <GraphNetworkMemo
            nodes={filteredGraphData.nodes}
            edges={filteredGraphData.edges}
            highlightedNodeIds={memoizedHighlightedNodeIds}
          />
        </div>
        <div className="chat-interface">
          <ChatInterface
            updateHighlightedNodes={updateHighlightedNodeIds}
            onSetSelectedDepth={setSelectedDepth}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
