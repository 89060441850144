import React, { useState, useRef, useEffect } from 'react';
import './styles/ChatSession.css';
import './styles/dots.css';
import './styles/Button.css';
import ReactMarkdown from 'react-markdown';
import { useAuth0 } from '@auth0/auth0-react';

function ChatSession({ id, updateHighlightedNodes, onSetSelectedDepth }) {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // conversation history loading
  const [showExplained, setShowExplained] = useState({}); // state to manage the visibility of explained text

  const inputRef = useRef(null);
  const { user: authUser, isAuthenticated, isLoading: authLoading, getAccessTokenSilently } = useAuth0();

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const isProduction = window.location.hostname === "www.arcintelligence-pv.de";

  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!authLoading && isAuthenticated) {
      fetchChatHistory();
      updateLastUsed();
    }
  }, [authLoading, isAuthenticated, id]);

  useEffect(() => {
    if (inputRef.current) {
      const handleFocus = () => {
        const inputEl = inputRef.current;
        setTimeout(() => {
          inputEl.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }, 300);
      };

      const inputEl = inputRef.current;
      inputEl.addEventListener('focus', handleFocus);
      return () => inputEl.removeEventListener('focus', handleFocus);
    }
  }, [inputRef.current]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const getAuthHeaders = async () => {
    try {
      const token = await getAccessTokenSilently();
      return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      };
    } catch (error) {
      console.error('Error fetching access token:', error);
      return { 'Content-Type': 'application/json' }; // Fallback headers
    }
  };

  const updateLastUsed = async () => {
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${BASE_URL}/update-last-used`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          chat_id: String(id),
          user: authUser,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error updating last used:', error);
    }
  };

  const fetchChatHistory = async () => {
    setIsLoading(true); // Start loading

    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${BASE_URL}/get-saved-message`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          chat_id: String(id),
          user: authUser,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status && data.status === 'No history found') {
        console.log('No history found');
      } else {
        console.log('Chat history:', data);

        // Ensure consistent data mapping
        const formattedMessages = Object.entries(data).map(([key, msg]) => ({
          id: key, // Using the key as the unique ID
          text: msg.text || "No text provided",
          username: msg.user || "Unknown",
          loading: false,
          plots: msg.plot || [], // Safeguard against missing fields
          explained: msg.explained || "", // Safeguard against missing fields
        }));

        setMessages(formattedMessages);
      }
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
    setIsLoading(false); // End loading
  };

  const sendMessage = async (text) => {
    if (text.trim() === '') {
      // If the text is empty, do nothing
      return;
    }

    const userName = authUser.given_name || authUser.name || "Guest";

    const newMessage = {
      text,
      username: userName,
      loading: true,
      plots: [], // Initialize with an empty array for plots
      explained: "" // Initialize with an empty string for explained
    };
    setMessages(prevMessages => [...prevMessages, newMessage]);

    // Save the message to the database
    try {
      console.log('Saving message:', newMessage);
      const headers = await getAuthHeaders();
      const response = await fetch(`${BASE_URL}/save-message`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          text: {text : newMessage.text},
          explained: newMessage.explained,
          user: authUser,
          chat_id: String(id),
          date: Date.now(), 
          plots : newMessage.plots
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error saving message:', error);
    }

    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${BASE_URL}/answer`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ messages: messages.concat(newMessage), user: authUser, ChatId: String(id), date: Date.now() }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.taskId) {
        checkTaskStatus(data.taskId, newMessage.id);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => prevMessages.map(msg =>
        msg.id === newMessage.id ? { ...msg, loading: false } : msg
      ));
    }
  };

  const checkTaskStatus = async (taskId, messageId) => {
    let requestCount = 0;  // Initialize the request counter

    const fetchTaskStatus = async () => {
      if (requestCount >= 500) {
        console.error('Maximum number of requests reached.');
        setMessages(prevMessages => prevMessages.map(msg =>
          msg.id === messageId ? { ...msg, loading: false } : msg
        ));
        return;  // Exit the function if the limit is reached
      }

      requestCount++;  // Increment the request counter

      try {
        const headers = await getAuthHeaders();
        const response = await fetch(`${BASE_URL}/get-result?taskId=${taskId}`, {
          method: 'GET',
          headers: headers,
        });
        const data = await response.json();

        if (data.status === 'completed') {
          updateVisualData(data.visual, messageId);  // Handle visual data
          const newMessage = {
            id: Date.now(),
            text: data.result || "No response received",
            username: "Arc",
            loading: false,
            plots: data.plots || [], // Include plots if available
            explained: data.explained || "" // Include explained text
          };

          setMessages(prevMessages => prevMessages.map(msg =>
            msg.id === messageId ? { ...msg, loading: false } : msg
          ).concat(newMessage));

          // Save the message to the database
          try {
            const saveHeaders = await getAuthHeaders();
            const saveResponse = await fetch(`${BASE_URL}/save-message`, {
              method: 'POST',
              headers: saveHeaders,
              body: JSON.stringify({
                text: newMessage.text,
                explained: newMessage.explained,
                user: authUser,
                chat_id: String(id),
                date: Date.now(), 
                plots : newMessage.plots
              }),
            });

            if (!saveResponse.ok) {
              throw new Error(`HTTP error! status: ${saveResponse.status}`);
            }
          } catch (error) {
            console.error('Error saving message:', error);
          }
        } else {
          setTimeout(fetchTaskStatus, 1000); // Wait 1 second before checking again
        }
      } catch (error) {
        console.error('Error checking task status:', error);
        setMessages(prevMessages => prevMessages.map(msg =>
          msg.id === messageId ? { ...msg, loading: false } : msg
        ));
      }
    };

    fetchTaskStatus(); // Initial call to start the process
  };

  const updateVisualData = (visual, messageId) => {
    try {
      let parsedVisual = JSON.parse(visual.trim());
      if (typeof parsedVisual === 'string') {
        parsedVisual = JSON.parse(parsedVisual);
      }

      // Directly call updateHighlightedNodes with new highlighted node IDs
      if (parsedVisual.nodes_to_highlight) {
        updateHighlightedNodes(parsedVisual.highlight_nodes);
      }

      onSetSelectedDepth(parsedVisual.level);
    } catch (error) {
      console.error('Error parsing visual data:', error);
    }
  };

  const toggleExplained = (index) => {
    setShowExplained((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  if (authLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <div>Please log in to access the chat.</div>;
  }

  const userName = authUser.given_name || authUser.name || "Guest";

  return (
    <div className="chat-session">
      <button
        className="button-17"
        style={{ position: 'absolute', top: '10px', right: '10px' }}
        onClick={() => sendMessage(`Hello my name is ${userName}! Can you please tell me what you can do? Give me some example questions?`)}
      >
        ?
      </button>

      <div className="messages">
        {messages.map((msg, index) => (
          <div key={msg.id} className={`message ${msg.username === "Arc" ? "message.ai" : "message.me"}`}>
            <div className="message-content-container">
              <div className="message-header">
                {msg.username}
                {msg.explained && msg.explained.length > 4 && (
                  <span 
                    className="square-icon" 
                    onClick={() => toggleExplained(index)}
                  ></span>
                )}
              </div>
              <div className="message-content" style={{ whiteSpace: 'pre-wrap' }}>
                {msg.text.includes('|') ? (
                  <div className="scrollable-table-container">
                    <pre>{msg.text}</pre>
                  </div>
                ) : (
                  <ReactMarkdown>{msg.text}</ReactMarkdown>
                )}
                {showExplained[index] && (
                  <div className="explained-text">{msg.explained}</div>
                )}
              </div>
              {msg.loading && <div className="loading-dots"><div></div><div></div><div></div></div>}
              {msg.plots && msg.plots.length > 0 && (
                <div className="message-plots">
                  {msg.plots.map((plot, index) => (
                    <img key={index} src={`data:image/png;base64,${plot}`} alt={`Plot ${index + 1}`} style={{ backgroundColor: 'black' }} />
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-container">
        <input
          ref={inputRef}
          type="text"
          placeholder={`Hey ${userName}! Type a message...`}
          className="chat-input"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              sendMessage(e.target.value);
              e.target.value = '';
            }
          }}
        />
        <button
          className="send-button"
          onClick={() => {
            const inputValue = inputRef.current.value;
            if (inputValue.trim() !== '') {
              sendMessage(inputValue);
              inputRef.current.value = '';
            }
          }}
          disabled={isLoading} // Disable button while loading
        >
          Send
        </button>
      </div>
    </div>
  );
}

export default ChatSession;
