import React, { useEffect, useState, useRef } from 'react';
import './styles/App.css';
import './styles/Spinner.css';

import { Network } from 'vis-network';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

function GraphNetwork({ nodes, edges, highlightedNodeIds = [1381, 1382, 1383] }) {
  const [nodePositions, setNodePositions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const networkRef = useRef(null);
  const containerRef = useRef(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const { isAuthenticated, isLoading: authLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchNodePositions = async () => {
      setIsLoading(true);
      console.log("Fetching node positions...");
      try {
        const authHeaders = await getAuthHeaders();
        const response = await axios.get(`${BASE_URL}/get-node-position`, authHeaders);

        if (response.status === 200 && response.data) {
          console.log("Node positions fetched successfully:", response.data);
          if (typeof response.data === 'object' && !Array.isArray(response.data)) {
            setNodePositions(response.data);
          } else {
            console.error("Unexpected data format received for node positions:", response.data);
          }
        } else {
          console.error(`Failed to fetch node positions: Status ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching node positions:", error.message || error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isAuthenticated && !authLoading) {
      fetchNodePositions();
    }
  }, [BASE_URL, isAuthenticated, authLoading, getAccessTokenSilently]);

  // Helper function to get auth headers
  const getAuthHeaders = async () => {
    try {
      const token = await getAccessTokenSilently();
      console.log("Access token fetched successfully.");
      return {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      };
    } catch (error) {
      console.error("Error fetching access token:", error);
      return {
        headers: {
          'Content-Type': 'application/json',
        },
      };
    }
  };

  useEffect(() => {
    if (!isAuthenticated || Object.keys(nodePositions).length === 0) {
      console.log("Waiting for authentication or node positions.");
      return;
    }

    console.log("Initializing network...");
    const container = document.getElementById('network-container');

    if (!container) {
      console.error("Network container not found");
      setIsLoading(false);
      return;
    }

    const placedNodes = nodes.map(node => {
      const position = nodePositions[node.id] || {};
      console.log(`Placing node ${node.id}:`, position);

      return {
        ...node,
        x: position.x || 0,
        y: position.y || 0,
        color: highlightedNodeIds.includes(Number(node.id))
          ? { background: '#A4F76A', border: '#A4F76A' }
          : node.color || {},
      };
    });

    const data = {
      nodes: placedNodes,
      edges: edges,
    };

    const options = {
      nodes: {
        shape: 'dot',
        font: {
          size: 14,
          color: '#fff',
        },
        chosen: {
          node: function (values, id, selected, hovering) {
            values.borderWidth = selected || hovering ? 2 : 1;
            values.shadow = selected ? true : false;
          },
        },
      },
      physics: {
        enabled: true,
        stabilization: {
          enabled: true,
          iterations: 100,
          updateInterval: 10,
          onlyDynamicEdges: false,
          fit: true
        },
        solver: 'barnesHut',
        barnesHut: {
          gravitationalConstant: -8000,
          centralGravity: 0.3,
          springLength: 95,
          springConstant: 0.04,
          damping: 0.09,
          avoidOverlap: 0.1
        },
        timestep: 0.5,
        adaptiveTimestep: true
      },
      edges: {
        color: {
          color: '#fff',
          highlight: '#fff',
          hover: '#fff',
        },
        // other edge options...
      },
      // other network options...
    };

    console.log("Network data:", data);
    console.log("Network options:", options);

    const network = new Network(container, data, options);
    networkRef.current = network;

    network.on("stabilizationIterationsDone", () => {
      console.log("Stabilization completed");
      network.fit(); // Adjust the view to include all nodes
      setIsLoading(false);
    });

    network.once("afterDrawing", () => {
      console.log("Graph rendering complete");
      setIsLoading(false);
    });

    network.stabilize(); // Force stabilization for debugging

    return () => {
      console.log("Cleaning up network...");
      if (networkRef.current) {
        networkRef.current.destroy();
        networkRef.current = null;
      }
    };
  }, [nodes, edges, nodePositions, highlightedNodeIds, isAuthenticated]);

  if (authLoading) {
    console.log("Authentication still loading...");
    return (
      <div style={{ height: '95%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    console.log("User not authenticated.");
    return (
      <div style={{ height: '95%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p>Please log in to view the network graph.</p>
      </div>
    );
  }

  console.log("Rendering network graph...");
  return (
    <div style={{ height: '95%', position: 'relative' }}>
      {isLoading && (
        <div className="loading-spinner" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        </div>
      )}
      <div
        id="network-container"
        style={{ height: '100%', visibility: isLoading ? 'hidden' : 'visible' }}
        ref={containerRef}
      ></div>
    </div>
  );
}

export default GraphNetwork;
